import React from 'react';

import lang from '../../../lang/pages/404.lang';
import {Container, Section} from '../../../ui/layout/Grid';
import Headline from '../../../ui/components/Headline';
import Paragraph from '../../../ui/components/Paragraph';
import Link from '../../../ui/components/Link';
import {routes} from '../../../model/navigation/routes';

export default function NotFound() {
    return (
        <Section>
            <Container width="narrow" className="text-center">
                <Headline type="h1">{lang.headline}</Headline>
                <Paragraph>{lang.paragraph}</Paragraph>
                <Link href={routes.start}>{lang.link}</Link>
            </Container>
        </Section>
    );
}
