import React from 'react';
import Layout from '../ui/layout/Layout';
import NotFoundContent from '../ui/domains/NotFound';

const NotFound = () => {
    return (
        <Layout>
            <NotFoundContent />
        </Layout>
    );
};

export default NotFound;
